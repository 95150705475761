<template>
  <div>
    <p class="blue-text font-weight-bold font-16 text-center">
      <b-button class="my-0 blue-text font-weight-bold" v-b-toggle="domId" role="button" variant="link">
         {{prefixText}}Total: {{ visit.total_cleaning_charges }}{{suffixText}} <br> <i class="fal fa-chevron-down"></i>
      </b-button>
    </p>
    <b-collapse :id="domId" :visible="open">
      <div class="float-parent font-mid-bold pb-4">
        <div class="float-left">{{ visit.pretty_requested_cleaning }} Cleaning</div>
        <div class="float-right blue-text">{{visit.cleaning_charges}}</div>
      </div>
      <div class="float-parent font-mid-bold clearfix mt-3 mb-2">
        <div class="float-left">Extra Charges</div>
        <div class="float-right blue-text">{{visit.extra_charges}}</div>
      </div>
      <div v-for="extra in savedExtras" class="float-parent gray-text clearfix mb-2">
        <div class="float-left dark-gray-text">{{extra.name}}{{extraQuantity(extra)}}</div>
        <div class="float-right dark-gray-text">{{extra.cost}}</div>
      </div>
      <hr>
      <div class="float-parent font-mid-bold clearfix">
        <div class="float-left">Total Amount</div>
        <div class="float-right blue-text">{{visit.total_cleaning_charges}}</div>
      </div>
      <slot name="actions"></slot>
    </b-collapse>
  </div>
</template>
<script>
import { savedExtras } from '@/utils'
export default {
  props: {
    domId: {
      type: String,
      required: true
    },
    prefixText: {
      type: String
    },
    suffixText: {
      type: String
    },
    visit: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    savedExtras() {
      if (this.visit && this.visit.extras) {
        return savedExtras(this.visit.extras)
      }
      return []
    }

  },
  methods: {
    extraQuantity(extra) {
      if (extra.quantity > 1) {
        return ' x' +extra.quantity
      }
      return
    },
  },
}
</script>
