<template>
  <div v-if="show" class="row mx-0 my-3 orange">
    <div class="col-sm-12">
      We received your booking request, we will get back to you as soon as possible to confirm this availability. {{companyEmail}} {{companyPhone}}
      <div class="col-sm" v-for="address in addresses">{{address}}</div>
    </div>
  </div>
</template>
<script>
import company_phone_mixin from '@/shared/company_phone_mixin'
export default {
  props: ['event', 'pending-properties'],
  mixins: [company_phone_mixin],
  computed: {
    //// lists the addresses only when there are more than one properties
    addresses() {
      if (this.pendingProperties && (this.pendingProperties.length > 1)) {
        return this.pendingProperties
      }
      return {}
    },
    show() {
      if (this.isAdmin) {
        return false
      }
      if (this.event) {
        if (this.event.pending && this.event.initial) {
          return true
        }
      }
      if (this.pendingProperties && (this.pendingProperties.length > 0)) {
        return true
      }
      return false
    },
  }
}
</script>
